import { createListenerMiddleware } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'

import fileActions from '../../actions/fileActions'
import { useTypedSelector } from '../../reducerTypes'
import { getFiles } from '../../selectors/fileSelectors'
import { purchaseOrderFileActions } from '../../slices/purchaseOrderFileSlice'
import { getSocket } from '../../socket'
import { BaseIdModel } from '../types/baseModelTypes'
import { getBaseQuery } from './apiHelpers'
import { createNotification } from './createCRUDApi'
import { BaseFileLinkModel, FileModel, PurchaseOrderFileLinkModel } from './fileApi'

type RawFileResultModel = {
  _embedded: {
    file: FileModel
  }
} & PurchaseOrderFileLinkModel

export const purchaseOrderFileApi = createApi({
  reducerPath: 'purchaseOrderFileApi',
  baseQuery: getBaseQuery('purchase_orders'),
  tagTypes: ['purchaseOrderFile'],
  endpoints: builder => ({
    getPurchaseOrderFiles: builder.query<RawFileResultModel[], number>({
      query: id => `/${id}/files`,
      providesTags: ['purchaseOrderFile'],
      onCacheEntryAdded: async(id, { updateCachedData, cacheEntryRemoved, cacheDataLoaded }) => {
        const socket = getSocket()
        try {
          await cacheDataLoaded
          socket.on('fileLink:purchase_order:created', (_channel: string, data: RawFileResultModel) => {
            if(data.purchaseOrderId === id) {
              updateCachedData(draft => {
                draft.push(data)
              })
            }
          })
        } catch{}
        await cacheEntryRemoved
        socket.off('fileLink:purchase_order:created')
      }
    }),
    postPurchaseOrderFile: builder.mutation<FileModel, { id: number, fileId: number }>({
      query: ({ id, fileId }) => ({
        url: `/${id}/files`,
        method: 'POST',
        body: { fileId }
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
        successMessage: 'Tiedosto tallennettu',
        errorMessage: 'Virhe tiedoston tallentamisessa'
      }),
      invalidatesTags: ['purchaseOrderFile']
    }),
    deletePurchaseOrderFile: builder.mutation<BaseIdModel, { purchaseOrderId: number, fileId: number }>({
      query: body => ({
        url: `/${body.purchaseOrderId}/files/${body.fileId}`,
        method: 'DELETE'
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => createNotification(queryFulfilled, {
        successMessage: 'Ostotilauksen tiedostolinkki poistettu',
        errorMessage: 'Virhe ostotilauksen tiedostolinkin poistamisessa'
      }),
      invalidatesTags: ['purchaseOrderFile']
    })
  })
})

const extractFilesFromRawResult = (rawResult: RawFileResultModel[]) => {
  return rawResult.reduce((acc, rawFileResult) => {
    const { _embedded: { file }, ...fileLink } = rawFileResult
    acc.files.push(file)
    acc.fileLinks.push(fileLink)
    return acc
  }, { files: [], fileLinks: [] } as { files: FileModel[], fileLinks: BaseFileLinkModel[] })
}

const listenerMiddleware = createListenerMiddleware()
listenerMiddleware.startListening({
  matcher: purchaseOrderFileApi.endpoints.getPurchaseOrderFiles.matchFulfilled,
  effect: async(action, listenerApi) => {
    if(!action?.payload) {
      return
    }
    const { files, fileLinks } = extractFilesFromRawResult(action.payload)
    listenerApi.dispatch(purchaseOrderFileActions.fetchSuccess(fileLinks))
    listenerApi.dispatch(fileActions.fetchSuccess(files))
  }
})
listenerMiddleware.startListening({
  matcher: purchaseOrderFileApi.endpoints.postPurchaseOrderFile.matchFulfilled,
  effect: async(action, listenerApi) => {
    if(action?.payload) {
      listenerApi.dispatch(purchaseOrderFileActions.createSuccess(action?.payload))
    }
  }
})
listenerMiddleware.startListening({
  matcher: purchaseOrderFileApi.endpoints.deletePurchaseOrderFile.matchFulfilled,
  effect: async(action, listenerApi) => {
    const id = action.meta.arg.originalArgs?.fileId
    if(id) {
      listenerApi.dispatch(purchaseOrderFileActions.deleteSuccess({ id }))
    }
  }
})

export const useGetPurchaseOrderFiles = (purchaseOrderId: number | null) => {
  const { data, ...rest } = purchaseOrderFileApi.useGetPurchaseOrderFilesQuery(purchaseOrderId!, { skip: purchaseOrderId == null })
  const { fileLinks } = extractFilesFromRawResult(data ?? [])
  const fileIds = fileLinks.map(({ fileId }) => fileId)
  const files = useTypedSelector(getFiles)
  const purchaseOrderFiles = files.filter(({ id }) => fileIds.includes(id))
  return { data: purchaseOrderFiles, ...rest }
}

export const { middleware: purchaseOrderFileMiddleware } = listenerMiddleware
export const {
  usePostPurchaseOrderFileMutation,
  useDeletePurchaseOrderFileMutation
} = purchaseOrderFileApi
