import { useMemo } from 'react'
import { Cell, ColumnMeta, flexRender } from '@tanstack/react-table'
import { clsx } from 'clsx'
import constant from 'lodash/constant'

import ExpandButton from '../Buttons/ExpandButton'
import { CommonCellProps } from './DataTableRow'

interface DataTableCellProps<TData, TValue> {
  cell: Cell<TData, TValue>
  commonCellProps?: CommonCellProps<TData>
  style?: object
  isEmptyCell?: boolean
}

const DataTableCell = <TData, TValue = unknown>({
  cell,
  commonCellProps,
  style,
  isEmptyCell
}: DataTableCellProps<TData, TValue>) => {
  const {
    colProps: {
      className: colClassName,
      style: colStyle,
      isEditable: isColEditable,
      ...colProps
    } = {},
    cellProps: { className: cellClassName, getClassName: getCellClassName, style: cellStyle, ...cellProps } = {},
    isHidden,
    onlyPinOnEdit
  } = cell.column.columnDef.meta ?? {} as ColumnMeta<TData, TValue>

  const isEditable = isColEditable !== false && !!commonCellProps?.isEditable

  const flexRenderProps = useMemo(() => ({
    ...commonCellProps,
    ...cell.getContext(),
    isEditable
  }), [cell, commonCellProps, isEditable])

  // isEmptyCell allows cell to occupy space on table, but to be empty
  if(isHidden && isEmptyCell == null) {
    return null
  }

  const pinPosition = cell.column.getIsPinned?.()
  const isPinned = pinPosition && (onlyPinOnEdit ? isEditable : true)
  const stickyStyles: React.CSSProperties = isPinned
    ? {
        position: 'sticky',
        zIndex: 100,
        right: pinPosition === 'right' ? 0 : undefined,
        left: pinPosition === 'left' ? 0 : undefined
      }
    : {}

  return (
    <div
      key={cell.id}
      role='cell'
      style={{
        width: cell.column.getSize(),
        ...stickyStyles,
        ...colStyle,
        ...cellStyle,
        ...style
      }}
      className={clsx('dt-td text-ellipsis', colClassName, cellClassName, getCellClassName?.(cell.row))}
      {...colProps}
      {...cellProps}
    >
      {isEmptyCell || cell.getIsPlaceholder()
        ? null
        : cell.getIsGrouped()
          ? (
            <ExpandButton expanded={cell.row.getIsExpanded()} onToggle={cell.row.getToggleExpandedHandler()}>
              {flexRender(
                cell.column.columnDef.cell,
                flexRenderProps
              )}{' '}({cell.row.subRows.length})
            </ExpandButton>
            )
          : flexRender(
            cell.getIsAggregated() && !!commonCellProps?.useAggregateCells ? (cell.column.columnDef.aggregatedCell || constant(null)) : cell.column.columnDef.cell,
            flexRenderProps
          )}
    </div>
  )
}

export default DataTableCell
