import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { EntityId } from '@reduxjs/toolkit'

import { store } from '.'
import { FederatedCredentialModel } from './api/federatedCredentialApi'
import { InsuranceModel } from './api/insuranceApi'
import { InsuranceCompanyModel } from './api/insuranceCompanyApi'
import { OccupationClassificationModel } from './api/occupationClassificationApi'
import { ContactModel } from './api/rtk/contactApi'
import { DeliveryTypeModel } from './api/rtk/deliveryTypeApi'
import { EmployeeLevelModel } from './api/rtk/employeeLevelsApi'
import { EmployeeRolesModel } from './api/rtk/employeeRolesApi'
import { EmployeeModel } from './api/rtk/employeesApi'
import {
  ContactFileLinkModel,
  FileModel,
  OfferFileLinkModel,
  ProductFileLinkModel,
  PurchaseOrderFileLinkModel,
  SalaryCalculationFileModel
} from './api/rtk/fileApi'
import { TableOptionsModel } from './api/rtk/index'
import { MemoModel } from './api/rtk/memoApi'
import { ProductModel } from './api/rtk/productApi'
import { PurchaseOrderModel } from './api/rtk/purchaseOrderApi'
import { PurchaseOrderRowModel } from './api/rtk/purchaseOrderRowApi'
import { PurchaseOrderStateModel } from './api/rtk/purchaseOrderStateApi'
import { SystemCustomerSettingsModel } from './api/rtk/settingsApi'
import { TargetModel } from './api/rtk/targetApi'
import { VatClassModel, VatCodeModel, VatRateModel } from './api/rtk/vatCodeApi'
import { SalaryCalculationModel, SalaryCalculationRowModel, SalaryPeriodModel } from './api/salaryPeriodApi'
import { BaseIdModel, BaseSyntheticIdModel } from './api/types/baseModelTypes'
import { PricingRuleModel } from './api/types/pricingRuleTypes'
import { SalaryTypeModel } from './api/types/systemCustomerTypes'
import { TimeRecordModel } from './api/types/timeRecordTypes'
import { ProductWarehouseStatsModel, WarehouseActionModel, WarehouseModel } from './api/types/warehouseTypes'
import { EmploymentModel, EmploymentSalaryTypeModel, TaxDeductionCardModel, WageEarnerModel } from './api/wageEarnerApi'
import { OfferCustomStateModel, OfferModel } from './containers/OfferPage/OfferTypes'
import { GenericState } from './helpers/sliceHelpers'
import { Modify } from './helpers/typeHelpers'

export interface ReducerMetadata {
  busy: boolean
  lastFetched: string | null
  fetchParams: Record<string, unknown>
}

export interface ReducerSearchData<Id extends EntityId = number> {
  isBusy: boolean
  searchResultIds: Id[]
  error: string | null
  searchTerm: string | null
}

export interface ReduxCrudRouter<T extends BaseIdModel | BaseSyntheticIdModel, Id extends EntityId = number> {
  records: T[]
  metadata: ReducerMetadata
  search: ReducerSearchData<Id>
  tableOptions?: TableOptionsModel
}

type TODO = BaseIdModel

// TODO: lisätään tyyppejä tarvittaessa
export interface RootState {
  systemCustomer: {
    salaryTypes: ReduxCrudRouter<SalaryTypeModel>
    pricingRuleSets: ReduxCrudRouter<PricingRuleModel>
    settingsData: SystemCustomerSettingsModel
  }
  whoAmI: {
    data: {
      employeeId: number
      userAccountId: number
      userAccountEmail: string
      isWizardRun: boolean
      isActive: boolean
      isInProduction: boolean
      isMaventaEnabled: boolean
      isFennoaEnabled: boolean
      isMfaForced: boolean
      serviceLevel: string
      pendingServiceLevel: string | null
      internallyEnabledFeatures: string[]
      stulAuthenticatedUntil: string | null
      mfaStatus: 'valid' | null
    }
    vatClasses: VatClassModel[]
    vatCodes: VatCodeModel[]
    vatRates: VatRateModel[]
  }
  timeRecords: ReduxCrudRouter<TimeRecordModel>
  employees: {
    levels: ReduxCrudRouter<EmployeeLevelModel>
  } & ReduxCrudRouter<EmployeeModel>
  employeeRoles: ReduxCrudRouter<EmployeeRolesModel>
  insurances: GenericState<InsuranceModel>
  insuranceCompanies: GenericState<InsuranceCompanyModel>
  occupationClassifications: GenericState<OccupationClassificationModel>
  offerCustomStates: ReduxCrudRouter<OfferCustomStateModel>
  offers: {
    files: ReduxCrudRouter<OfferFileLinkModel>
    memos: ReduxCrudRouter<TODO>
    offerDefaults: ReduxCrudRouter<TODO>
    postRows: ReduxCrudRouter<TODO>
    posts: ReduxCrudRouter<TODO>
  } & ReduxCrudRouter<OfferModel>
  supplyOffers: ReduxCrudRouter<TODO>
  wageEarners: {
    wageEarners: GenericState<WageEarnerModel>
    taxDeductionCards: GenericState<TaxDeductionCardModel>
    employments: GenericState<EmploymentModel>
    employmentSalaryTypes: GenericState<EmploymentSalaryTypeModel, string>
  }
  federatedCredentials: GenericState<FederatedCredentialModel>
  purchaseOrders: {
    purchaseOrders: GenericState<PurchaseOrderModel>
    purchaseOrderRows: GenericState<PurchaseOrderRowModel>
    purchaseOrderStates: GenericState<PurchaseOrderStateModel>
    memos: GenericState<MemoModel>
    files: GenericState<PurchaseOrderFileLinkModel>
  }
  salaryPeriods: {
    salaryPeriods: GenericState<SalaryPeriodModel>
    salaryCalculations: GenericState<SalaryCalculationModel>
    salaryCalculationRows: GenericState<SalaryCalculationRowModel>
    salaryCalculationFiles: GenericState<SalaryCalculationFileModel>
  }
  products: {
    files: ReduxCrudRouter<ProductFileLinkModel>
    productTasks: ReduxCrudRouter<TODO>
    productWarehouseStats: ReduxCrudRouter<ProductWarehouseStatsModel>
  } & ReduxCrudRouter<ProductModel>
  warehouses: {
    warehouseActions: ReduxCrudRouter<WarehouseActionModel>
    warehouseProductStats: ReduxCrudRouter<ProductWarehouseStatsModel>
  } & ReduxCrudRouter<WarehouseModel>
  deliveryTypes: GenericState<DeliveryTypeModel>
  contacts: {
    contactExtras: ReduxCrudRouter<TODO>
    customers: ReduxCrudRouter<TODO>
    files: ReduxCrudRouter<ContactFileLinkModel>
    stats: ReduxCrudRouter<TODO>
    targets: ReduxCrudRouter<TargetModel>
  } & ReduxCrudRouter<ContactModel>
  files: ReduxCrudRouter<FileModel>
}

type AppDispatch = typeof store.dispatch

// Override inferred types with RootState
export type EveliaRootState = Modify<ReturnType<typeof store.getState>, RootState>

export const useTypedDispatch = useDispatch as () => AppDispatch
export const useTypedSelector = useSelector as TypedUseSelectorHook<EveliaRootState>
